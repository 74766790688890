<!--
 * @Author: your name
 * @Date: 2021-04-13 16:17:57
 * @LastEditTime: 2021-04-15 13:48:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\views\user\index.vue
-->
<template>
  <div>
    <div v-if="showLoading ==  false">
      <div class="topmsgclass">
        <div class="shezhi" @click="goEditUpwd">
          <div></div>
          <!-- <img class="shezhiimg" src="../../assets/shezhi_1.png" alt="" /> -->
        </div>
        <div class="usermsg">
          <div class="theuc">
            <div style="width: 1.25rem;height:1.25rem;margin-right:0.3rem;">
              <div v-if="userInfo.headimg_url=='' ">
                 <img v-if="userInfo.sex == '女' " class="userimg" src="../../assets/nv.jpg" alt="" />
                 <img v-else class="userimg" src="../../assets/user_1.jpg" alt="" />
              </div>
             <div v-else>
                 <img class="userimg" :src="userInfo.headimg_url" alt="" />
             </div>
              
            </div>
            
            <div>
              <div class="yuan">
                <div class="uname">{{userInfo.realname}}</div>
                <!-- <div class="zhi">{{userInfo.new_identity}}</div> -->
                <img class="seximg" v-if="userInfo.sex == '男' || userInfo.sex == '' " src="../../assets/sex1.png" alt="" />
                <img class="seximg" v-else src="../../assets/sex2.png" alt="" />
              </div>
              <div class="gs">最新入职：{{userInfo.company_name == "" ? "暂无" : userInfo.company_name}}</div>
            </div>
          </div>
          <van-icon @click="clickmsg" size=".39rem" name="arrow" />
        </div>
        <div class="numclass">
          <div  class="imgstyle">
            <div>{{look}}</div>
            <div class="ji">看过</div>
          </div>
          <div  class="imgstyle">
            <div>{{auth}}</div>
            <div class="ji">授权通知</div>
          </div>
          <div  class="imgstyle">
            <div>{{upload}}</div>
            <div class="ji">被推荐</div>
          </div>
          <div  class="imgstyle">
            <div>{{follow}}</div>
            <div class="ji">关注</div>
          </div>
        </div>
      </div>
      <div class="centermsg">
        <div class="imgstyle" @click="goUserResume">
          <img src="../../assets/s_1.png" alt="" />
          <div>我的简历</div>
        </div>
        <div class="imgstyle" @click="goUserProfession">
          <img src="../../assets/s_2.png" alt="" />
          <div>职业档案</div>
        </div>
        <div  class="imgstyle" @click="gomyCompany">
          <img src="../../assets/s_3.png" alt="" />
          <div>我的企业</div>
        </div>
      </div>
      <div class="pl" @click="goAbout">
        <div class="plw">
          <img src="../../assets/m_1.png" alt="" />
          <div>关于萝卜猎手个人端</div>
        </div>
        <van-icon size=".3rem" name="arrow" />
      </div>
      <div class="pl" @click="goEditUpwd">
        <div class="plw">
          <img src="../../assets/m_2.png" alt="" />
          <div>帐号设置</div>
        </div>
        <van-icon size=".3rem" name="arrow" />
      </div>
      <div class="pl">
        <div class="plw">
          <img src="../../assets/m_3.png" alt="" />
          <div>版本号</div>
        </div>
        <div class="ban">
          <div>V1.1版</div>
          <!-- <van-icon size=".3rem" name="arrow" /> -->
        </div>
      </div>
      <div  class="pl" @click="goStatusChange">
        <div class="plw">
          <img src="../../assets/m_4.png" alt="" />
          <div>用户身份</div>
        </div>
        <div class="ban">
          <div>个人</div>
          <van-icon size=".3rem" name="arrow" />
        </div>
      </div>

       <div @click="logout" style="margin-bottom:2rem;" class="pl">
        <div class="plw">
          <img src="../../assets/logout.png" alt="" />
          <div>退出登录</div>
        </div>
        <div class="ban">
         
          <van-icon size=".3rem" name="arrow" />
        </div>
      </div>


    </div>
		<van-loading v-if="showLoading == true" size="45" class="loading" type="spinner" />
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant';
export default {
  data(){
    return{
      userInfo:{},
      auth:"",
      follow:"",
      look:"",
      upload:"",
      showLoading:false
    }
  },
  mounted(){
    this.showLoading = true
    this.$http.post("/user/v1/Pro_files/myHome",{reqType:"myHome"})
    .then((res)=>{
      let response = JSON.parse(res.data)
      console.log(response)
      if(response.code == 0){
        this.showLoading = false
        this.userInfo = response.data.userInfo
        this.auth= response.data.auth
        this.look = response.data.look
        this.follow = response.data.follow
        this.upload = response.data.upload
      }else{
        Toast.fail(res.msg)
      }
      
      // console.log(response)
    })
    .catch((e)=>{
      console.log(e)
    })
  },
  methods: {
    logout(){
      Dialog.confirm({
        title: '标题',
        message: '确定退出登录吗？',
      })
        .then(() => {
          sessionStorage.clear()
          localStorage.clear()
          setTimeout(() => {
            this.$router.push("./login")
          }, 1000);
        })
        .catch(() => {
          // on cancel
        });
    },
    goStatusChange(){
      this.$router.push("./statusChange")
    },
    goAbout(){
      this.$router.push("./about")
    },
    gomyCompany(){
      this.$router.push("./companyList")
    },
    goUserProfession(){
      this.$router.push("./userProfession")
    },
    clickmsg() {
      this.$router.push({
        path: "/usermsg",
        query: {
          id: "1",
        },
      });
    },
    goEditUpwd(){
      this.$router.push("./editUpwd")
    },
    goUserResume(){
      this.$router.push("./userResume")
    }
  },
};
</script>
<style scoped>
.loading{
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-0.2rem;
}
.imgstyle{
  text-align: center;
}
.shezhiimg{
  width: .4rem;
  height: .37rem;
}
.ban {
  display: flex;
  align-items: center;
}
.ban > div {
  margin-right: 0.25rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  line-height: 0.48rem;
}
.shu {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #848484;
  line-height: 0.48rem;
}
.pl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.39rem 0.46rem;
  background-color: #ffffff;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.48rem;
  margin-bottom: 0.04rem;
}
.plw {
  display: flex;
  align-items: center;
}
.plw img {
  width: 0.35rem;
  margin-right: 0.28rem;
}
.centermsg img {
  width: 0.6rem;
}
.centermsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.38rem 0.68rem;
  background-color: #ffffff;
  font-size: 0.24rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.48rem;
  margin: 0.14rem 0;
}
.ji {
  font-size: 0.24rem;
  font-weight: 400;
  color: #555555;
  line-height: 0.48rem;
}
.numclass {
  margin-top: 0.49rem;
  padding-left: .25rem;
  padding-right: .25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 0.46rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.48rem;
}
.gs {
  font-size: 0.24rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.3rem;
}
.uname {
  font-size: 0.48rem;
  font-weight: 600;
  color: #333333;
  line-height: 0.48rem;
  margin-right:0.2rem;
}
.zhi {
  margin-right: 0.16rem;
  margin-left: 0.3rem;
  background: #00beff;
  border-radius: 0.08rem;
  font-size: 0.18rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.05rem 0.1rem;
}
.yuan {
  display: flex;
  align-items: center;
  margin-bottom: 0.28rem;
}
.seximg {
  width: 0.4rem;
}
.theuc {
  display: flex;
  align-items: center;
}
.userimg {
  width: 1.25rem;
  height:1.25rem;
  margin-right: 1.5rem;
  border-radius:50%;
}
.usermsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topmsgclass {
  background-color: #ffffff;
  padding: 0.4rem 0.38rem;
}
.shezhi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.39rem;
}
</style>